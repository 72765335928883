import React from 'react'
import ThemeContext from '../../context/ThemeContext'
import Footer from './Footer'
import Header from './Header'

const Layout = ({children}) => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <div className={theme.dark ? 'main dark' : 'main'}>
          <div className='u-container'>
            <Header />
            <div className='u-grid'>{children}</div>
          </div>
          <Footer />
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default Layout