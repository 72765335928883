import React from 'react'
import { ThemeProvider } from './src/context/ThemeContext'
import  Layout  from './src/components/Layout/Layout'
import '@andythomo/type-fig-pro'
import './src/css/variables.css'
import './src/css/base.css'
import './src/css/global.css'


export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
      {element}
  </ThemeProvider>
)

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}