import React from 'react'
import * as styles from './ToggleSwitch.module.css'

const ToggleSwitch = ({ isToggled, onToggle }) => {
  return (
    <div>
      <span className={styles.title}>
        {isToggled ? 'Dark Theme' : 'Light Theme'}
      </span>
      <label className={styles.toggle}>
        <input
          type='checkbox'
          className={styles.checkbox}
          checked={isToggled}
          onChange={onToggle}
        />
        <span className={styles.slider} />
      </label>
    </div>
  )
}

export default ToggleSwitch