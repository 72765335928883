import { Link } from 'gatsby'
import React from 'react'
import * as styles from './Footer.module.css'

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div>
        <p>
          <b>FIG PEOPLE LIMITED</b>
        </p>
        <p>
          Registered in England & Wales with
          <b> Company No:</b> 12144270
        </p>
        <p>
          <b>Registered office address:</b> Barons Court, Unit 1 Graceways,
          Whitehills Business Park, Blackpool, England, FY4 5GP
        </p>
        <p>
          <b>VAT Number: </b>GB3299 09268
        </p>
        <p>
          <b>ICO Registration Number: </b>ZB301489
        </p>
      </div>
      <Link to='/Privacy'>Privacy</Link>
    </div>
  )
}

export default Footer