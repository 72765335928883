import * as React from 'react'

const Logo = ({ textColor, iconColor, width }) => (
  <svg
    id='Layer_2'
    data-name='Layer 2'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 320 171.72'
    width={width}
  >
    <g id='Layer_1-2' data-name='Layer 1'>
      <path
        fill='var(--color-secondary)'
        d='M20.09 132.05c0-22.16 18.03-40.19 40.19-40.19V71.77c-14.24 0-25.84-11.59-25.84-25.84s11.59-25.84 25.84-25.84 25.84 11.59 25.84 25.84h20.09C106.21 20.6 85.61 0 60.28 0S14.35 20.6 14.35 45.93c0 13.58 5.93 25.79 15.33 34.21C11.93 90.64 0 109.97 0 132.05v21.53h62.32v-20.09H20.09v-1.44Z'
      />
      <path
        // {...(iconColor ? `fill=${iconColor}` : 'fill="#57c5aa"')}
        fill='var(--color-secondary)'
        d='M77.23 60.24v7.51h12.31v5.97H77.23v12.22h-6.24V54.26H89.9v5.97H77.23ZM99.91 54.27v31.68h-6.25V54.27h6.25ZM136.2 71.38c0 9.14-6.47 15.21-15.56 15.21-9.91 0-16.97-7.38-16.97-16.43s7.15-16.52 16.61-16.52c6.02 0 11.13 3.03 13.8 7.38l-5.34 3.08c-1.45-2.54-4.66-4.39-8.51-4.39-6.02 0-10.36 4.43-10.36 10.45s4.21 10.32 10.81 10.32c4.98 0 8.14-2.4 9.23-6.02h-9.59v-5.52h15.88v2.44Z'
      />
      <path
        // {...(textColor ? `fill=${textColor}` : 'fill="#3f6769"')}
        fill='var(--color-primary)'
        d='M115.67 112.3c0 11.71-9.29 21-21.36 21H83.32v20.55H70.99V91.3h23.32c12.06 0 21.36 9.29 21.36 21Zm-12.24 0c0-5.45-3.84-9.47-9.12-9.47H83.32v18.94h10.99c5.27 0 9.12-4.11 9.12-9.47ZM138.15 144.65c4.47 0 8.04-1.88 10.01-4.47l9.29 5.36c-4.2 6.08-10.9 9.56-19.48 9.56-15.01 0-24.49-10.27-24.49-23.59s9.56-23.59 23.59-23.59c13.22 0 22.61 10.46 22.61 23.59 0 1.7-.18 3.22-.45 4.74h-33.69c1.61 5.9 6.52 8.4 12.6 8.4Zm10.01-17.33c-1.43-6.43-6.25-9.02-11.08-9.02-6.16 0-10.37 3.31-11.71 9.02h22.79ZM161.57 131.51c0-13.31 10.45-23.59 23.59-23.59s23.68 10.28 23.68 23.59-10.55 23.59-23.68 23.59-23.59-10.27-23.59-23.59Zm35.74 0c0-7.24-5.27-12.33-12.15-12.33s-12.06 5.09-12.06 12.33 5.27 12.33 12.06 12.33 12.15-5.09 12.15-12.33ZM258.52 131.51c0 13.32-9.83 23.59-21.8 23.59-6.52 0-11.26-2.41-14.48-6.52v23.14h-11.53v-62.55h11.53v5.27c3.22-4.11 7.96-6.53 14.48-6.53 11.97 0 21.8 10.28 21.8 23.59Zm-11.53 0c0-7.5-5.27-12.6-12.42-12.6s-12.33 5.1-12.33 12.6 5.27 12.6 12.33 12.6 12.42-5.09 12.42-12.6ZM260.4 88.62h11.53v65.23H260.4V88.62ZM298.47 144.65c4.47 0 8.04-1.88 10.01-4.47l9.29 5.36c-4.2 6.08-10.9 9.56-19.48 9.56-15.01 0-24.49-10.27-24.49-23.59s9.56-23.59 23.59-23.59c13.22 0 22.61 10.46 22.61 23.59 0 1.7-.18 3.22-.45 4.74h-33.69c1.61 5.9 6.52 8.4 12.6 8.4Zm10.01-17.33c-1.43-6.43-6.25-9.02-11.08-9.02-6.16 0-10.37 3.31-11.71 9.02h22.79Z'
      />
    </g>
  </svg>
)

export default Logo
