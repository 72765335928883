import React from 'react'
import * as styles from './Header.module.css'

import ThemeContext from '../../context/ThemeContext'
import Logo from '../Icons/Logo'
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch'
import { Link } from 'gatsby'

const Header = () => {
  return (
    <ThemeContext.Consumer>
      {(theme) => (
        <>
          <div className={styles.header}>
            <Link to='/'>
            <Logo width={'150px'}/>
            </Link>
            <ToggleSwitch
              onToggle={theme.toggleDark}
              isToggled={theme.dark} />
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  )
}

export default Header
